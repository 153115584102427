<template>
  <div class="home-contact-form">
    <div
      class="lg:py-20 lg:px-20 bg-[url('/images/user/bg-contact.png')] bg-no-repeat bg-center bg-cover flex lg:flex-row flex-col lg:gap-20 gap-6 bg-cover max-lg:flex-col-reverse"
    >
      <div class="lg:p-0 max-lg:w-full max-lg:px-4">
        <h3 class="text-[#F49D2E] text-2xl font-bold mb-9">
          {{ $t('Liên đoàn Thương mại và') }} <br>
          {{ $t('Công nghiệp Việt Nam') }}
        </h3>
        <ul class="text-white text-base font-normal">
          <li class="lg:mb-5 mb-2">
            <a class="text-white" href="">{{ $t('Số 9, Đào Duy Anh, Đống Đa, Hà Nội') }}</a>
          </li>
          <li class="lg:mb-5 mb-2">
            <span>{{ $t('Điện thoại: ') }}</span>
            <a class="text-white text-base" href="">024.3577.0632</a>/
            <a class="text-white text-base" href="">024.3574.2022 (máy lẻ 355)</a>
          </li>
          <li class="lg:mb-5 mb-2">
            <span class="text-base">{{ $t('Fax: ') }}</span>
            <a class="text-white text-base" href="">024.3577.1459</a>
          </li>
          <li class="lg:mb-5 mb-2">
            <span class="text-base">{{ $t('Email: ') }}</span>
            <a class="text-white text-base" href="">banphapche@vcci.com.vn</a>
          </li>
        </ul>
        <span class="text-base font-semibold text-white mt-6 inline-block max-sm:mb-4">
          Với sự hỗ trợ của Dự án Tạo thuận lợi Thương mại - Cơ quan Phát triển Quốc tế Hoa Kỳ
        </span>
      </div>
      <div class="min-w-50% p-4 lg:p-0">
        <div class="flex-col">
          <div class="logo-contact">
            <img src="/images/home/logo-contact.svg" class="m-auto">
          </div>
          <div class="header-contact mt-6">
            <p class="text-base font-normal text-[#FFFFFF] text-center">
              Ý kiến của bạn là động lực giúp chúng tôi phát triển hơn nữa
            </p>
          </div>
          <div class="give-a-comment mt-5 flex justify-center" @click="giveForUsTheComment()">
            <button class="text-white bg-[#6ABD45] py-2.5 px-5">Nhận xét về chúng tôi</button>
          </div>
        </div>
        <el-dialog v-model="notLogin" title="Đăng nhập" width="30%" class="loginFormGiveUsComment">
          <span>Bạn cần đăng nhập để có thể yêu cầu tham gia diễn đàn</span>
          <el-form ref="formRef" :model="form" :rules="rules" label-position="top" class="mt-4">
            <el-form-item
              :label="$t('Tên đăng nhập')"
              prop="email"
              :inline-message="hasError('email')"
              :error="getError('email')"
            >
              <el-input v-model="form.email" size="large" class="w-360px" placeholder="Nhập Email" />
            </el-form-item>
            <el-form-item
              :label="$t('Mật khẩu')"
              prop="password"
              :inline-message="hasError('password')"
              :error="getError('password')"
            >
              <el-input
                v-model="form.password"
                size="large"
                type="password"
                show-password
                placeholder="Nhập Mật Khẩu"
              />
            </el-form-item>
          </el-form>

          <el-button class="w-full" color="#2078CE" @click.prevent="handleLogin()">{{ $t('Đăng nhập') }}</el-button>
          <span class="my-4 flex">Bạn chưa có tài khoản?
            <a class="inline-block pl-1 cursor-pointer" @click.prevent="goToRegisterPage()">Đăng ký</a></span>
        </el-dialog>
        <el-dialog v-model="showGiveUsCommentForm" title="Nhận xét về chúng tôi" width="40%" class="sendCommentForUs">
          <el-form ref="form" :model="feedbackForm" :rules="rules">
            <div class="mb-5 mt-3">
              <div class="transition-all duration-200 text-base">
                <el-form-item>
                  <el-rate v-model="feedbackForm.rate" size="large" />
                </el-form-item>
                <div class="text-textBlackSecondary mb-3">Viết phản hồi của bạn</div>
                <el-form-item>
                  <el-input v-model="feedbackForm.feedbackContent" type="textarea" :placeholder="$t('Nội dung')" rows="10" />
                </el-form-item>
              </div>
              <div class="flex w-full justify-center">
                <el-button color="#D9D9D9" class="py-1 px-6 mt-[10px]" @click.prevent="cancelSubmit()">
                  {{ $t('Hủy bỏ') }}
                </el-button>
                <el-button
                  color="#6ABD45"
                  class="py-1 px-6 mt-[10px] text-white sendCommentButton"
                  :loading-form="loadingForm"
                  @click.prevent="handleFeedbackForm"
                >
                  {{ $t('Gửi đi') }}
                </el-button>
              </div>
            </div>
          </el-form>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showGiveUsCommentForm: false,
      notLogin: false,
      form: this.$inertia.form({
        email: null,
        password: null,
      }),
      rules: {
        email: [{ required: true, message: 'Trường tên đăng nhập không được bỏ trống.', trigger: 'blur' }],
        password: [{ required: true, message: 'Trường password không được bỏ trống.', trigger: 'blur' }],
      },
      feedbackForm: this.$inertia.form({
        username: this.$page?.props?.auth?.user?.email ?? null,
        rate: null,
        feedbackContent: null,
      }),
      loading: false,
    }
  },
  computed: {
    user() {
      return this.$page?.props?.auth?.user ?? {}
    },
  },
  methods: {
    giveForUsTheComment() {
      if (!this.user?.id) {
        this.notLogin = true
      } else {
        this.showGiveUsCommentForm = true
      }
    },
    handleLogin() {
      this.$refs['formRef'].validate(async (valid) => {
        if (!valid) return

        this.form.post('/login', {
          preserveScroll: true,
          onSuccess: () => {
            this.$inertia.visit(this.route('home'))
          },
          onError: () => {
            this.$message({ message: 'Đăng nhập không thành công', type: 'error' })
          },
        })
      })
    },
    getError(field) {
      return this.hasError(field) ? this.form.errors[field] : ''
    },
    hasError(field) {
      return !!(this.form.errors && this.form.errors[field])
    },
    goToRegisterPage() {
      this.$inertia.visit(this.route('register'))
    },
    cancelSubmit() {
      this.showGiveUsCommentForm = false
    },
    handleFeedbackForm() {
      this.$refs['form'].validate(async (valid) => {
        if (!valid) return

        this.feedbackForm.post('/api/userFeedback', {
          preserveScroll: true,
          onSuccess: () => {
            this.$inertia.visit(this.route('home'))
            this.$message({ message: 'Gửi nhận xét thành công', type: 'success' })
            this.showGiveUsCommentForm = false
          },
          onError: () => {
            this.$message({ message: 'Gửi nhận xét không thành công', type: 'error' })
          },
        })
      })
    }
  },
}
</script>

<style>
.sendCommentForUs .el-dialog__header {
  background-color: white !important;
}
.sendCommentForUs .el-dialog__header .el-dialog__title {
  color: black !important;
}
.sendCommentForUs .el-dialog__header .el-dialog__headerbtn .el-dialog__close {
  color: black !important;
}
@media (max-width: 768px) {
  .loginFormGiveUsComment {
    width: 80% !important;
  }
  .sendCommentForUs {
    width: 80% !important;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .sendCommentForUs {
    width: 80% !important;
  }
  .loginFormGiveUsComment {
    width: 80% !important;
  }
}
.sendCommentButton {
  color: white;
}
</style>
